@font-face {
    font-family: 'Cooper Black';
    src: url('./assets/fonts/cooper-black.eot') format('embedded-opentype'),
        url('./assets/fonts/cooper-black.woff') format('woff'),
        url('./assets/fonts/cooper-black.woff2') format('woff2'),
        url('./assets/fonts/cooper-black.ttf') format('truetype'),
        url('./assets/fonts/cooper-black.svg') format('svg');
}

@font-face {
    font-family: 'Futura Bk BT';
    src: url('./assets/fonts/futura-book.eot') format('embedded-opentype'),
        url('./assets/fonts/futura-book.woff') format('woff'),
        url('./assets/fonts/futura-book.ttf') format('truetype'),
        url('./assets/fonts/futura-book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Futura Lt BT';
    src: url('./assets/fonts/futura-light.eot') format('embedded-opentype'),
        url('./assets/fonts/futura-light.woff') format('woff'),
        url('./assets/fonts/futura-light.ttf') format('truetype'),
        url('./assets/fonts/futura-light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Futura Md';
    src: url('./assets/fonts/futura-book.eot') format('embedded-opentype'),
        url('./assets/fonts/futura-book.woff') format('woff'),
        url('./assets/fonts/futura-book.ttf') format('truetype'),
        url('./assets/fonts/futura-book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
